<template>
  <div>
    <v-chip
      v-for="filter in filterList"
      :key="filter.field"
      color="grey"
      :disabled="disabled"
      class="mr-1 mb-2 cursor-pointer px-2"
      @click="$emit('select', filter)">
      <div class="filter-list__circle mr-1">
        <v-icon
          small
          color="#fff">
          mdi-plus
        </v-icon>
      </div>
      {{ filter.field }}
    </v-chip>
  </div>
</template>
<script>
export default {
  name: 'SearchFilterList',
  props: {
    filterList: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped lang="scss">
.filter-list {
  &__circle {
    border-radius: 50%;
    padding: 0 2px 2px 2px;
    background-color: var(--v-lightBlue-base) !important;
  }
}
</style>
